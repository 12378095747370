<template>
  <div id="app">
    <div id="nav">
      <div class="navWrap">
        <img class="logo" src="./assets/img/logo.png" alt="">
        <div class="menu" @click="showMenu">
          <img src="./assets/img/menu.svg" alt="">
        </div>
      </div>
    </div>
    <main id="main">
      <keep-alive>
        <router-view/>
      </keep-alive>
    </main>
    <div id="footer">
      <div class="scan">
        <img src="./assets/img/scan.png" alt="">
        <span>扫码关注公众号</span>
      </div>
      <span class="copyright">沪ICP备08005943号-2© 2022 曼恒数字</span>
    </div>
    <a-drawer
        placement="left"
        :closable="true"
        :visible="visible"
        :headerStyle="headerStyle"
        :drawerStyle="drawerStyle"
        width="217"
        @close='onClose'
    >
      <router-link class="nav home" to="/" @click.native="onMenuClicked">
        <span></span>
        <span>首页</span>
      </router-link>
      <router-link class="nav about" to="about" @click.native="onMenuClicked">
        <span></span>
        <span>关于曼恒</span>
      </router-link>
      <router-link class="nav concat" to="contact" @click.native="onMenuClicked">
        <span></span>
        <span>联系我们</span>
      </router-link>
      <div class="scan">
        <img src="./assets/img/scan.png" alt="">
        <div class="scan-title">扫码关注公众号</div>
      </div>
    </a-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      headerStyle: {
        height: '50px',
        backgroundColor: '#1F1F1F'
      },
      drawerStyle: {
        backgroundColor: '#1F1F1F',
        position: 'relative'
      }
    }
  },
  methods: {
    showMenu() {
      this.visible = true
    },
    onClose() {
      this.visible = false;
    },
    onMenuClicked() {
      this.visible = false
    }
  }
}
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #121212;
  padding-top: 50px;
}

#nav {
  width: 100%;
  height: 50px;
  background-color: #121212;
  position: fixed;
  top: 0;
  z-index: 100;

  .navWrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .logo {
      width: 77px;
    }

    .menu {
      position: absolute;
      left: 16px;
      width: 28px;
      height: 28px;

      img {
        //width: 100%;
      }
    }
  }

}

#main {
  flex: 1;
  overflow-y: auto;
}

#footer {
  width: 100%;
  background-color: #222222;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .copyright {
    font-family: arial !important;;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #CCCCCC;
    margin-bottom: 20px;
  }

  .scan {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 60px;
    }
    span{
      font-family: Source Han Sans CN;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      color: #FFFFFF;
      margin-top: 5px;
    }
  }
}

// 抽屉的样式
.ant-drawer {
  z-index: 9999999999 !important;
}

.ant-drawer-close {
  left: 0;
  height: 50px !important;
  line-height: 50px !important;
  color: #ffffff !important;

  .anticon anticon-close {
    font-weight: bold;
    font-size: 18px !important;
  }
}

.ant-drawer-body {
  padding: 0 !important;
  //position: relative;

  .nav {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;

    &.router-link-exact-active {
      span:first-child {
        background-color: #F89227;
      }

      span:nth-child(2) {
        color: #F89227;
      }
    }

    span:first-child {
      transition: all 0.2s;
      width: 4px;
      height: 16px;
    }

    span:nth-child(2) {
      transition: all 0.2s;
      padding-left: 12px;
      font-family: arial !important;;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      letter-spacing: -0.408px;
      color: #ffffff;
    }
  }

  .scan {
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    img {
      width: 100px;
      height: 100px;
    }

    .scan-title {
      text-align: center;
      width: 100%;
      font-family: arial !important;;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.21em;
      /* 移动端/6 */
      color: #FFFFFF;
      margin-top: 7px;
    }
  }
}
</style>
