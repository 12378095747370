<template>
  <div class="ideaVR">
    <img src="../../assets/img/home/ideavr.png" class="logo" alt="">
    <h2 class="title">IdeaXR </h2>
    <h3 class="subtitle">国产自主研发XR引擎 拥有完全知识产权</h3>
    <p class="des">IdeaXR内容创作引擎是一款为教育、企业等行业领域打造的XR引擎开发工具。具有跨平台（支持国产系统）和易学易用的特性，提供快速搭建场景、图形化交互编辑、多人协同演练等功能，可应用于教育、高端工业、应急等行业领域。</p>
    <div class="model">
      <div class="model-container">
        <!--      <img class="editor" src="../../assets/img/home/ideaVR-img.png" alt="">-->
<!--        <iframe title="3D Model" id="model-view" class="hero__iframe" width="auto" height="auto"-->
<!--                src="https://sketchfab.com/models/9688985db84d447580d40e40e1649407/embed?annotations_visible=0&amp;autospin=-0.1&amp;autostart=1&amp;camera=0&amp;double_click=0&amp;internal=1&amp;max_texture_size=1024&amp;orbit_constraint_pan=1&amp;orbit_constraint_zoom_in=40&amp;orbit_constraint_zoom_out=60&amp;preload=1&amp;scrollwheel=0&amp;sound_enable=0&amp;transparent=1&amp;ui_animations=0&amp;ui_annotations=0&amp;ui_ar=1&amp;ui_color=white&amp;ui_fadeout=0&amp;ui_fullscreen=1&amp;ui_help=0&amp;ui_infos=0&amp;ui_inspector=0&amp;ui_settings=0&amp;ui_stop=0&amp;ui_theatre=0&amp;ui_vr=0&amp;ui_watermark=0"-->
<!--                frameborder="0" allow="autoplay; fullscreen; vr" allowvr="" allowfullscreen="" mozallowfullscreen="true"-->
<!--                webkitallowfullscreen="true" onmousewheel=""></iframe>-->
<!--        <div class="mask-top"></div>-->
<!--        <div class="mask-bottom"></div>-->
        <img src="../../assets/img/home/ideavr_intro.png" alt="">
      </div>
      <div class="btns">
        <div class="apply" style="margin-right: 20px;">
          <img src="../../assets/img/video.svg" alt="">
          <span @click="viewVideo">观看视频</span>
        </div>
        <a href="https://apply.ideavr.top/site/register" target="_blank">
          <div class="document">
            <img src="../../assets/img/home/more-icon.svg" alt="">
            <span>申请试用</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IdeaVr",
  methods: {
    viewVideo() {
      layui.use('layer', function () {
        const layer = layui.layer;
        layer.open({
          type: 1,
          title: false,
          shade: [0.9, '#000'],
          shadeClose: true,
          // closeBtn: 0,
          area: '100%',
          scrollbar: false,
          content: `<video autoplay controls src="https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/IdeaVR.mp4" style="width: 100%;outline: none">`
        })
      });
    }
  }
}
</script>

<style scoped lang="scss">
.ideaVR {
  background-color: #121212;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    margin-top: 80px;
    width: 50px;
  }

  .title {
    font-family: arial !important;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    /* 移动端/2 */
    color: #ffffff;
    margin-top: 20px;
  }

  .subtitle {
    font-family: arial !important;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    margin-top: 20px;
  }

  .des {
    font-family: arial !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.08em;
    color: #ffffff;
    padding: 0 16px;
    text-align: justify;
    margin-top: 20px;
  }

  .model {
    background-color: #121212;
    width: 100%;
    //padding-top: 53px;
    padding-bottom: 80px;
    //margin-top: 20px;
    //height: 228px;
    background-image: url("../../assets/img/home/ideaVR-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 35px;
    //margin: 80px 0;
    overflow: hidden;

    .editor {
      width: 343px;
    }

    .model-container {
      position: relative;

      img{
        width: 100%;
        margin: 40px 0;
      }
      #model-view {
        border: none;
        width: 100%;
        height: 550px;
      }

      .mask-top {
        position: absolute;
        width: 100%;
        height: 45px;
        top: 0;
        background-color: #121212;
      }

      .mask-bottom {
        position: absolute;
        width: 100%;
        height: 65px;
        bottom: 0;
        background-color: #121212;
      }
    }
  }

  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    //margin-top: 40px;

    .apply {
      width: 120px;
      height: 32px;
      background: linear-gradient(315.22deg, #5D9134 23.66%, #A8CD5E 76.46%);
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;

      img {
        width: 14px;
      }

      span {
        margin-left: 10px;
        font-family: arial !important;;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        /* identical to box height */
        color: #FFFFFF;
      }
    }

    .document {
      @extend .apply;
      margin-right: 20px;
      background: transparent;
      border: 1px solid #BBBBBB;
    }
  }
}
</style>
