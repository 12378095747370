<template>
    <div class="cases">
        <h1 class="title">客户与案例</h1>
        <p class="des">积累了国内外2000+知名企业、高端工业、高校、职校和三甲医院等企事业单位，拥有行业前沿的应用案例。</p>
        <div class="caseSwiperWrap">
            <swiper class="swiper" :options="swiperOption">
                <swiper-slide v-for="(slide, index) in slides" :key="index">
                    <div class="content">
                        <img class="img" :src="slide.img" alt="" />
                        <h2 class="name">{{ slide.title }}</h2>
                        <h3 class="subname">{{ slide.subtitle }}</h3>
                        <p class="detail">{{ slide.des }}</p>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Cases',
        data() {
            return {
                swiperOption: {
                    slidesPerView: 'auto',
                    spaceBetween: 7,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                },
                slides: [
                    {
                        img: require('../../assets/img/home/cases/cases_bjdx.jpg'),
                        title: '北京大学',
                        subtitle: '沉浸式XR实验教学中心',
                        des: '曼恒数字为北京大学地球与空间科学学院建设了沉浸式XR实验中心，这是国家较早一批建设的国家级虚拟仿真实验教学中心。',
                    },
                    {
                        img: require('../../assets/img/home/cases/cases_shjd.jpg'),
                        title: '上海交通大学',
                        subtitle: 'XR创新教育实验中心',
                        des: '上海交大-XR创新教育实验中心”由上海交大文创学院和曼恒数字联合建设。中心具备教育创新实验室、创新技术培训课程、创新应用与内容创作工作室三大职能。',
                    },
                    {
                        img: require('../../assets/img/home/cases/cases_mkdh.jpg'),
                        title: '中国慕课大会',
                        subtitle: 'XR+5G技术与高校实验教学',
                        des: '曼恒自主研发XR内容创作引擎IdeaXR，支持快速构建多人异地协同工作环境。专注于解决用户在“异地多人协同”场景下的协同培训，实现高效的沟通和“教、学、考、练”环节全覆盖。',
                    },
                    {
                        img: require('../../assets/img/home/cases/cases_daqing.jpg'),
                        title: '大庆油田',
                        subtitle: '应急演练XR培训系统',
                        des:
                            '在国家危险化学品应急救援实训大庆基地，依托曼恒数字自主研发的XR内容创作引擎IdeaXR，建成了“大庆油田应急演练-XR培训系统”。',
                    },
                    {
                        img: require('../../assets/img/home/cases/cases_pdjc.jpg'),
                        title: '上海浦东机场',
                        subtitle: '消防应急救援指挥视觉模拟系统',
                        des:
                            '项目结合上海机场集团在机场应急保障方面的先进经验和做法，利用GIS(地理信息系统)、3D建模、虚拟现实和5G通信等高新技术，通过沉浸式和多人协同的手段对业务人员进行全方位的实训和考核。',
                    },
                    // {
                    //     img: require('../../assets/img/home/cases/cases_htmy.jpg'),
                    //     title: '中国航天某院',
                    //     subtitle: '防空导弹虚拟现实模拟系统',
                    //     des:
                    //         '该系统一改传统的军事训练，特别是大杀伤性武器的军事演习的缺点，如：消耗大量资金和物资，安全性难以保证等。并且该系统可以在实战演习条件下改变状态，来反复进行各种战场态势下的战术和决策研究。',
                    // },
                    {
                        img: require('../../assets/img/home/cases/cases_shsf.jpg'),
                        title: '中国商飞',
                        subtitle: '国产大飞机C919项目',
                        des:
                            '曼恒数字为商飞试飞中心提供机载测试系统地面验证平台，对实际飞机中飞行的参数进行监控分析，确保飞机在预飞和实际飞行中相关参数的准确和正常，为C919成功首飞提供了坚实的数据保障。',
                    },
                    /* 2022/04/28 mm disabled
                    {
                        img: require('../../assets/img/home/cases/上海电力股份有限公司.jpg'),
                        title: '上海电力股份有限公司',
                        subtitle: '大丰海上风电VR（虚拟现实）交互系统',
                        des:
                            '构建风力发电机设备操作模拟仿真环境，在减少危险的前提下进行高仿真虚拟培训，通过沉浸式培训模式对操作人员进行全系统突破时空限制，降低培训成本，提高培训效率，保障安全生产',
                    },
                    */
                    {
                        img: require('../../assets/img/home/cases/cases_ams.jpg'),
                        title: '艾默生Emerson',
                        subtitle: '亚太异地虚拟协同演示平台',
                        des:
                            '艾默生是一家拥有百年历史的世界500强企业，曼恒数字为其打造了亚太区虚拟现实体验中心，以“IdeaXR异地虚拟协同演示平台”为核心，帮助艾默生为客户用异地虚拟协同的创新方式展示设计方案。',
                    },
                    /* 2022/04/28 mm disabled
                    {
                        img: require('../../assets/img/home/cases/英特格拉.jpg'),
                        title: '英特格拉',
                        subtitle: 'ICP颅内压监测探头植入VR教学培训系统',
                        des:
                            '美国英特格拉生命科学是全球神经外科医疗器械供应商，曼恒与其联合推出的ICP颅内压监测探头植入VR教学培训系统，极大突破传统临床培训局限，让颅内压监测探头植入操作培训更加便捷、有效',
                    },
                    */
                ],
            }
        },
    }
</script>

<style lang="scss">
    .cases {
        background-color: #121212;
        overflow: hidden;

        .title {
            font-family: arial !important;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            color: #ffffff;
            margin-top: 80px;
        }

        .des {
            font-family: arial !important;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 180%;
            text-align: justify;
            letter-spacing: 0.08em;
            color: #ffffff;
            padding: 0 16px;
            margin-top: 30px;
        }

        .caseSwiperWrap {
            padding-left: 16px;
            margin-top: 40px;

            .swiper {
                width: 100%;
                height: 500px;
            }

            .swiper-slide {
                width: 343px;

                .content {
                    width: 100%;
                    height: 466px;
                    background-color: #292929;

                    .img {
                        height: 217px;
                    }

                    .name {
                        font-family: arial !important;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        letter-spacing: 0.05em;
                        color: #ffffff;
                        margin-top: 30px;
                    }

                    .subname {
                        font-family: arial !important;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        letter-spacing: 0.05em;
                        color: #ffffff;
                        margin-top: 10px;
                    }

                    .detail {
                        font-family: arial !important;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 180%;
                        text-align: justify;
                        color: #ffffff;
                        padding: 0 30px;
                        margin-top: 20px;
                    }
                }
            }

            .swiper-pagination-bullet {
                width: 12px;
                height: 2px;
                border-radius: 0;
                margin: 0 !important;
                background: rgba(255, 255, 255, 0.6);
            }

            .swiper-pagination-bullet-active {
                background-color: #f89227;
            }
        }
    }
</style>
