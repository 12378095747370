<template>
  <div class="home">
    <div class="bannerWrap">
      <Banner></Banner>
    </div>
    <div class="ideavrWrap">
      <IdeaVr></IdeaVr>
    </div>
    <div class="fourgroupWrap">
      <FourGroup></FourGroup>
    </div>
    <div class="eightgroupWrap">
      <EightGroup></EightGroup>
    </div>
    <div class="industryWrap">
      <Industry></Industry>
    </div>
    <div class="caseWrap">
      <Cases></Cases>
    </div>
    <div class="customWrap">
      <Custom></Custom>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from "@/components/home/Banner";
import IdeaVr from "@/components/home/IdeaVr";
import FourGroup from "@/components/home/FourGroup";
import EightGroup from "@/components/home/EightGroup";
import Industry from "@/components/home/Industry";
import Cases from "@/components/home/Cases";
import Custom from "@/components/home/Custom";

export default {
  name: 'Home',
  components: {
    Banner,
    IdeaVr,
    FourGroup,
    EightGroup,
    Industry,
    Cases,
    Custom
  }
}
</script>
