<template>
  <div class="banner">
    <swiper class="mySwiper" :options="swiperOption">
      <swiper-slide v-for="(banner,index) in banners" :key="index" class="banner-item">
        <img :src="banner.bg" alt="" @click="link(index)">
      </swiper-slide>
      <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      swiperOption: {
        preventClicks: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet(index, className) {
            return `<span class="${className} swiper-pagination-bullet-custom"></span>`
          }
        }
      },
      banners: [
        {
          bg: require('../../assets/img/home/banner/banner2.jpg')
        },
        {
          bg: require('../../assets/img/home/banner/banner4.jpg')
        },
        {
          bg: require('../../assets/img/home/banner/banner3.jpg')
        },
        // {
        //   // bg: require('../../assets/img/home/banner/banner1.jpg')
        //   bg: require('../../assets/img/home/banner/banner5.jpg')
        // },
      ]
    }
  },
  methods: {
    link() {
      // if (index === 0) {
      //   window.open('http://ideavr.top/avatar/', "_blank")
      // }
    },
  }
}
</script>

<style lang="scss">
.banner {
  width: 100%;
  height: 400px;


  .banner-item {
    width: 100%;

    img {
      width: 100%;
    }
  }
}

.mySwiper {
  height: 100%;

  .swiper-pagination-bullet-custom {
    width: 12px;
    height: 2px;
    //background: rgba(0, 0, 0, 0.1);
    background: #BBBBBB;
    border-radius: 0;

    &.swiper-pagination-bullet-active {
      background-color: #F89227;
    }
  }
}
</style>
