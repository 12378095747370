<template>
  <div class="custom">
    <h2 class="title">服务客户2000+</h2>
    <div class="container">
      <div class="item" v-for="(item,index) in customs" :key="index">
        <div class="imgWrap">
          <img :src="item.icon" alt="">
        </div>
        <p class="name">{{item.name}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Custom",
  data() {
    return {
      customs: [
        {
          icon: require('../../assets/img/home/zhgy_jxk.svg'),
          name: '中航工业'
        },
        {
          icon: require('../../assets/img/home/zght_jxk.svg'),
          name: '中国航天'
        },
        {
          icon: require('../../assets/img/home/zgh_jxk.svg'),
          name: '中广核'
        },
        {
          icon: require('../../assets/img/home/zgsf_jxk.svg'),
          name: '中国商飞'
        },
        {
          icon: require('../../assets/img/home/zczg_jxk.svg'),
          name: '中船重工'
        },
        {
          icon: require('../../assets/img/home/jxk_zgzt.svg'),
          name: '中国中铁'
        },
        {
          icon: require('../../assets/img/home/jxk_zgsy.svg'),
          name: '中国石油'
        },
        {
          icon: require('../../assets/img/home/jxk_zgdw.svg'),
          name: '国家电网'
        },
        {
          icon: require('../../assets/img/home/jxk_bjdx.svg'),
          name: '北京大学'
        },
        {
          icon: require('../../assets/img/home/jxk_qhdx.svg'),
          name: '清华大学'
        },
        {
          icon: require('../../assets/img/home/jxk_shjd.svg'),
          name: '上海交大'
        },
        {
          icon: require('../../assets/img/home/jxk_zjdx.svg'),
          name: '浙江大学'
        },
        {
          icon: require('../../assets/img/home/jxk_bjgz.svg'),
          name: '北京工职'
        },
        {
          icon: require('../../assets/img/home/jxk_cqdz.svg'),
          name: '重庆电子'
        },
        {
          icon: require('../../assets/img/home/jxk_shjc.svg'),
          name: '上海机场'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.custom {
  background-color: #121212;
  overflow: hidden;

  .title {
    margin-top: 80px;
    font-family: arial !important;;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
  }

  padding-bottom: 30px;

  .container {
    width: auto;
    margin: 0 auto;
    margin-top: 26px;
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: flex-start;
    padding: 0 10px;
    text-align: left;

    .item {
      width: 71px;
      //margin-left: 10px;
      //margin-right: 10px;
      display: inline-block;
      margin-bottom: 33px;
      text-align: center;

      .imgWrap {
        //width: 44px;
        //height: 44px;
        overflow: hidden;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 66%;
        }
      }

      .name {
        font-family: arial !important;;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #ffffff;
        margin-top: 4px;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100px;
        text-align: center;
        margin-left: -15px;
      }
    }
  }
}
</style>
