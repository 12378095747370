<template>
  <div class="industry">
    <h1 class="title">行业应用</h1>
    <p class="des">用XR技术为教育领域、应急安全、高端工业等行业客户提供“三高四不”（高风险、高投入、高难度、不可达到、不可触及、不可重现、不可逆转）场景下的训练、培训和教学等的产品和解决方案。</p>
    <div class="industries">
      <div class="item" v-for="(item,index) in industries" :key="index">
        <img :src="item.icon" alt="">
        <h2 class="name">{{item.name}}</h2>
        <!--        <p class="more">了解详情</p>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Industry",
  data() {
    return {
      industries: [
        {
          icon: require('../../assets/img/home/jywb.svg'),
          name: '教育领域',
          url: ''
        },
        {
          icon: require('../../assets/img/home/yjyl.svg'),
          name: '应急安全',
          url: ''
        },
        {
          icon: require('../../assets/img/home/gfjg.svg'),
          name: '高端工业',
          url: ''
        },
        /* 2022/04/28 mm disabled
        {
          icon: require('../../assets/img/home/高端制造.svg'),
          name: '高端制造',
          url: ''
        },
        {
          icon: require('../../assets/img/home/医疗医学.svg'),
          name: '医疗医学',
          url: ''
        },
        {
          icon: require('../../assets/img/home/影音娱乐.svg'),
          name: '影音娱乐',
          url: ''
        },
        */
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.industry {
  background-color: #121212;
  padding: 0 16px;
  overflow: hidden;

  .title {
    margin-top: 80px;
    font-family: arial !important;;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #ffffff;
  }

  .des {
    font-family: arial !important;;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.08em;
    color: #ffffff;
    text-align: justify;
    margin-top: 30px;
  }

  .industries {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 40px;

    .item {
      width: 108px;
      height: 108px;
      background-color: #292929;
      margin-bottom: 10px;

      img {
        width: 40px;
        margin-top: 15px;
      }

      .name {
        margin-top: 10px;
        font-family: arial !important;;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        color: #ffffff;
      }

      .more {
        font-family: arial !important;;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #cccccc;
        margin-top: 10px;
      }
    }
  }
}
</style>
