<template>
  <div class="fourGroup">
    <div class="itemWrap" v-for="(item, index) in fourGroup" :key="index" :style="{ backgroundColor: item.bgColor }">
      <div class="itemLogo">
        <img class="logo" :class="{ seven: item.isSeven }" :src="item.logo" alt=""/>
        <div class="logoDes">
          <p class="title">{{ item.title }}</p>
          <p class="subtitle">{{ item.subtitle }}</p>
        </div>
      </div>
      <img class="itemImg" :src="item.img" alt=""/>
      <p class="itemDes">{{ item.des }}</p>
      <div class="extra" v-if="item.extra">
        <div class="extraItem">
          <span>3800+</span>
          <span>XR课程资源</span>
        </div>
        <div class="extraItem">
          <span>200+</span>
          <span>专业类别</span>
        </div>
        <div class="extraItem">
          <span>100+</span>
          <span>系列</span>
        </div>
      </div>
      <div class="btns">
        <div
            class="btn"
            :class="{ type1: i.type === 1, type2: i.type === 2, type3: i.type === 3, type4: i.type === 4, type5: i.type === 5, type6: i.type === 6 }"
            v-for="(i, ind) in item.btns"
            :key="ind"
        >
          <img :src="i.icon" alt=""/>
          <span v-if="i.video" @click="viewVideo(i.url)">{{ i.name }}</span>
          <span v-else
          ><a :href="i.url" target="_blank" :download="i.url">{{ i.name }}</a></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FourGroup',
  data() {
    return {
      fourGroup: [
        {
          logo: require('../../assets/img/home/7guang-logo.svg'),
          title: 'MR一体机',
          subtitle: '面向B端行业市场的专业MR头盔',
          img: require('../../assets/img/home/7guang.jpg'),
          bgColor: '#1f1f1f',
          isSeven: true,
          des:
              '作为一款面向B端行业版的全新MR一体机，7光搭载高通骁龙XR2平台，采用CV头手6DOF光学定位系统，实现真4K高清显示。标配8GB+512GB大容量内存，支持58-72mm范围IPD瞳距连续调节。以其强悍的硬件性能，自然流畅的操控体验，为行业用户带来更真实的沉浸感和卓越的体验感。',
          btns: [
            {
              type: 1,
              icon: require('../../assets/img/video.svg'),
              name: '观看视频',
              video: true,
              url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/7guang.mp4',
            },
            // {
            //   type: 4,
            //   icon: require('../../assets/img/home/apply-btn.svg'),
            //   name: '资料下载',
            //   video: false,
            //   url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/%E6%9B%BC%E6%81%927%E5%85%89VR%E4%B8%80%E4%BD%93%E6%9C%BA%E4%BA%A7%E5%93%81%E4%BB%8B%E7%BB%8D.jpg',
            // },
          ],
        },
        {
            logo: require('../../assets/img/home/gmotion0511.png'),
            title: 'G-Motion5.0',
            subtitle: 'XR人机交互动作捕捉定位光学系统',
            img: require('../../assets/img/home/GM50-02.jpg'),
            bgColor: '#1f1f1f',
            des:
                '新一代GM 5.0追踪系统，是一款主动式亚毫米级的XR交互追踪。创新性的主动式摄像头安装在运动点，主动拍摄固定光学成像点位，目标更清晰，抗干扰能力强，时延低，精度高，运算量小。结合LinkXR及双手柄操作，用户可以更自然简便的方式体验XR内容。',
            // btns: [
            //     {
            //         type: 5,
            //         icon: require('../../assets/img/home/notes.svg'),
            //         name: '资料下载',
            //         video: false,
            //         url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/G-MotionV50.pdf',
            //     },
            // ],
        },
        /* 2022/04/27 mm disabled start
        {
          logo: require('../../assets/img/home/vrdo.png'),
          img: require('../../assets/img/home/vrdo-bg.jpg'),
          title: 'VRDO 创度',
          subtitle: '工程技术领域专用VR引擎',
          bgColor: '#121212',
          des:
              '高效整合行业数据，快速搭建工业场景；帮助机械、自动化等用户产品教学，同时也为高端制造业客户提供产品展示、设计评审、客户培训、市场宣传和数字化样机设计等应用',
          btns: [
            // {
            //   type: 1,
            //   icon: require('../../assets/img/video.svg'),
            //   name: '观看视频',
            //   video: true,
            //   url: '/video/VRDO.mp4',
            // },
            // {
            //   type: 2,
            //   icon: require('../../assets/img/home/apply-btn.svg'),
            //   name: '申请试用',
            //   video: false,
            //   url: 'https://app.shb.ltd/p/109299#/login?redirect=%2FchooseEvent',
            // },
          ],
        },
        2022/04/27 mm disabled end */
        /* 2022/04/27 mm disabled start
        {
          logo: require('../../assets/img/home/vrbee.png'),
          img: require('../../assets/img/home/vrbee-bg.jpg'),
          title: 'vrBee',
          subtitle: '新一代VR大空间解决方案',
          bgColor: '#1f1f1f',
          des:
              '方案包括了交互追踪系统，头戴显示系统，图形渲染系统，无线推流系统，内容播控系统，以及VR引擎六大系统；能够给予用户从案例制作，内容使用管理，无线多人协同，大空间沉浸式体验的一站式解决方案',
          btns: [
            {
              type: 1,
              icon: require('../../assets/img/video.svg'),
              name: '观看视频',
              video: true,
              url: '/video/VRBEE.mp4',
            },
          ],
        },
        2022/04/27 mm disabled end */
        {
          logo: require('../../assets/img/home/commander4x.png'),
          img: require('../../assets/img/home/commander.jpg'),
          title: 'Commander',
          subtitle: 'XR数字化模拟训练系统',
          bgColor: '#1f1f1f',
          des:
                  'Commander（指挥官）是曼恒推出的应急安全领域进行模拟演练平台产品，针对高复杂、高投入、高危险、技术密集的行业领域，以XR技术搭建场景，定义训练流程脚本，设定训练目标和考核要求，让学员进入虚拟场景完成现实中难以实现的训练任务。该产品广泛用于石油石化，矿山码头，机场医院等客户群体，大大的降低客户日常演练的成本，提高演练的难度和灵活度。',
          btns: [
            {
              type: 1,
              icon: require('../../assets/img/video.svg'),
              name: '观看视频',
              video: true,
              url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/Commander.mp4',
            },
            // {
            //   type: 6,
            //   icon: require('../../assets/img/home/notes.svg'),
            //   name: '资料下载',
            //   video: false,
            //   url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/Commander.pdf',
            // },
          ],
        },
        {
          logo: require('../../assets/img/home/vrbox.png'),
          img: require('../../assets/img/home/vrbox-bg.jpg'),
          extra: true,
          title: 'XRBOX',
          subtitle: 'XR内容平台',
          bgColor: '#121212',
          des:
              '面向行业用户的XR内容平台，链接教育用户和优秀内容开发者，帮助本科、高校、职校等用户解决优质XR内容稀缺、内容获取渠道有限、内容标准不一、设备之间互联互通困难等现实问题。',
          btns: [
            {
              type: 1,
              icon: require('../../assets/img/video.svg'),
              name: '观看视频',
              video: true,
              url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/VRBOX.mp4',
            },
            {
              type: 3,
              icon: require('../../assets/img/home/refresh.svg'),
              name: '体验VRBOX',
              video: false,
              url: 'https://www.gvrbox.com',
            },
          ],
        },
      ],
    }
  },
  methods: {
    viewVideo(url) {
      layui.use('layer', function () {
        const layer = layui.layer
        layer.open({
          type: 1,
          title: false,
          shade: [0.9, '#000'],
          shadeClose: true,
          // closeBtn: 0,
          area: '100%',
          scrollbar: false,
          content: `<video autoplay controls src="${url}" style="width: 100%;outline: none">`,
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
.fourGroup {
  .itemWrap {
    overflow: hidden;

    .itemLogo {
      margin-top: 80px;
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        width: 50px;

        &.seven {
          width: 66px;
        }
      }

      .logoDes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 20px;

        .title {
          font-family: arial !important;
          font-style: normal;
          font-size: 22px;
          //line-height: 24px;
          font-weight: bolder;
          color: #ffffff;
        }

        .subtitle {
          font-family: arial !important;
          font-style: normal;
          font-weight: bolder;
          font-size: 14px;
          //line-height: 18px;
          letter-spacing: 0.08em;
          margin-top: 6px;
          color: #ffffff;
        }
      }
    }

    .itemImg {
      margin-top: 30px;
      width: 100%;
      //height: 300px;
    }

    .itemDes {
      text-align: justify;
      padding: 0 16px;
      font-family: arial !important;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 180%;
      letter-spacing: 0.08em;
      color: #ffffff;
      margin-top: 40px;
    }

    .extra {
      display: flex;
      justify-content: space-between;
      padding: 0 26px;
      margin-top: 40px;

      .extraItem {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        span:first-child {
          font-family: arial !important;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          color: #ffffff;
        }

        span:nth-child(2) {
          font-family: arial !important;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          color: #ffffff;
          margin-top: 8px;
        }
      }
    }

    .btns {
      display: flex;
      justify-content: center;

      .btn {
        width: 120px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px 20px 80px 20px;

        img {
          width: 12px;
        }

        span {
          font-family: arial !important;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: #ffffff;
          margin-left: 6px;

          a {
            color: #ffffff;
          }
        }

        &.type1 {
          border: 1px solid #ffffff;
          box-sizing: border-box;
          border-radius: 36px;
        }

        &.type2 {
          background: linear-gradient(311.6deg, #3a7179 -0.61%, #a2d1d9 104.67%);
          /* 移动端/2 */

          //border: 1px solid #333333;
          box-sizing: border-box;
          border-radius: 36px;

          span {
            color: #ffffff;
          }
        }

        &.type3 {
          @extend .type2;
          background: linear-gradient(321.22deg, #652dbf 25.95%, #ac43f2 83.29%);
        }

        &.type4 {
          @extend .type2;
          background: linear-gradient(to right, rgb(201, 85, 255), rgb(0, 227, 219));
        }

        /* 2022/04/27 mm added */
        &.type5 {
          @extend .type2;
          background: linear-gradient(rgba(104, 189, 133, 1), rgba(103, 199, 170, 1), rgba(72, 195, 198, 1));
        }

        /* 2022/04/27 mm added */
        &.type6 {
          @extend .type2;
          background: linear-gradient(rgba(237, 128, 93, 1), rgba(204, 75, 108, 1), rgba(126, 63, 138, 1));
        }
      }
    }
  }
}
</style>
