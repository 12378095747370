<template>
    <div class="eightGroup">
        <div class="itemWrap" v-for="(item, index) in eightGroup" :key="index">
            <img class="img" :src="item.img" alt="" />
            <div class="iconWrap">
                <img :src="item.icon" alt="" />
            </div>
            <h2 class="title">{{ item.title }}</h2>
            <h3 class="subtitle">{{ item.subtitle }}</h3>
            <div class="btns">
                <div
                    class="btn"
                    v-for="(btn, ind) in item.btns"
                    :key="ind"
                    :class="{ type1: btn.type === 1, type2: btn.type === 2, type3: btn.type === 3, type4: btn.type === 4, type5: btn.type === 5, type6: btn.type === 6 }"
                >
                    <span v-if="btn.video" @click="viewVideo(btn.url)">{{ btn.name }}</span>
                    <span v-else
                        ><a :href="btn.url" target="_blank" :download="btn.url">{{ btn.name }}</a></span
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EightGroup',
        data() {
            return {
                eightGroup: [
                    {
                        img: require('../../assets/img/home/vrbee-bg.jpg'),
                        icon: require('../../assets/img/home/vrbee-logo.svg'),
                        title: 'xrBee 小蜜蜂',
                        subtitle: 'XR大空间多人全身追踪',
                        btns: [
                            {
                                type: 1,
                                name: '观看视频',
                                video: true,
                                url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/vrbee_0428.mp4',
                            },
                            // {
                            //     type: 6,
                            //     name: '资料下载',
                            //     video: false,
                            //     url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/vrbee_intro_0428.pdf',
                            // },
                        ],
                    },
                    {
                        img: require('../../assets/img/home/octopus.jpg'),
                        icon: require('../../assets/img/home/8group-octopus-icon.svg'),
                        title: 'OCTOPUS 八爪鱼',
                        subtitle: 'XR智能中控系统软件',
                        btns: [
                            {
                                type: 1,
                                name: '观看视频',
                                video: true,
                                url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/OCTOPUS.mp4',
                            },
                            // {
                            //     type: 4,
                            //     name: '申请试用',
                            //     video: false,
                            //     url: 'https://app.shb.ltd/p/109299#/login?redirect=%2FchooseEvent',
                            // },
                        ],
                    },
                    /* 2022/04/28 mm disabled
                    {
                        img: require('../../assets/img/home/3dcity.jpg'),
                        icon: require('../../assets/img/home/8group-3dcity-icon.svg'),
                        title: '3DCITY',
                        subtitle: 'VR模型素材库',
                        btns: [
                            {
                                type: 1,
                                name: '观看视频',
                                video: true,
                                url: '/video/3Dcity.mp4',
                            },
                            {
                                type: 2,
                                name: '立即体验',
                                video: false,
                                url: 'http://www.3dcity.com',
                            },
                        ],
                    },
                    */
                    {
                        img: require('../../assets/img/home/linkxr.jpg'),
                        icon: require('../../assets/img/home/8group-linkvr-icon.svg'),
                        title: 'LinkXR',
                        subtitle: 'XR内容适配平台',
                        btns: [
                            {
                                type: 1,
                                name: '观看视频',
                                video: true,
                                url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/LINKVR.mp4',
                            },
                            // {
                            //     type: 5,
                            //     name: '申请试用',
                            //     video: false,
                            //     url: 'https://app.shb.ltd/p/109299#/login?redirect=%2FchooseEvent',
                            // },
                        ],
                    },
                    {
                        img: require('../../assets/img/home/flyvr.jpg'),
                        icon: require('../../assets/img/home/8group-flyvr-icon.svg'),
                        title: 'FlyXR 飞流',
                        subtitle: 'XR内容串流软件',
                        btns: [
                            {
                                type: 1,
                                name: '观看视频',
                                video: true,
                                url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/FLYVR.mp4',
                            },
                            // {
                            //     type: 3,
                            //     name: '申请试用',
                            //     video: false,
                            //     url: 'https://app.shb.ltd/p/109299#/login?redirect=%2FchooseEvent',
                            // },
                        ],
                    },
                    /* 2022/04/28 mm disabled
                    {
                        img: require('../../assets/img/home/gmotion-bg.jpg'),
                        icon: require('../../assets/img/home/g-motion.png'),
                        title: 'G-Motion',
                        subtitle: '高精度光学位置追踪系统',
                        btns: [
                            {
                                type: 1,
                                name: '资料下载',
                                video: false,
                                url: '/pdf/Gmotion宣传册.pdf',
                            },
                        ],
                    },
                    */
                    {
                        img: require('../../assets/img/home/vrtree.jpg'),
                        icon: require('../../assets/img/home/8group-vrtree-icon.svg'),
                        title: 'XR-Tree',
                        subtitle: '多人实训交互显示产品',
                        btns: [
                            // {
                            //     type: 1,
                            //     name: '资料下载',
                            //     video: false,
                            //     url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/VR-TREE%E7%B3%BB%E5%88%97.pdf',
                            // },
                        ],
                    },
                    {
                        img: require('../../assets/img/home/vr2.jpg'),
                        icon: require('../../assets/img/home/8group-vr2-icon.svg'),
                        title: 'XR平方',
                        subtitle: '大屏交互显示产品',
                        btns: [
                            // {
                            //     type: 1,
                            //     name: '资料下载',
                            //     video: false,
                            //     url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/VR%E5%B9%B3%E6%96%B9%E7%B3%BB%E5%88%97.pdf',
                            // },
                        ],
                    },
                    {
                        img: require('../../assets/img/home/vr3.jpg'),
                        icon: require('../../assets/img/home/8group-vr3-icon.svg'),
                        title: 'XR立方',
                        subtitle: '洞穴交互显示产品',
                        btns: [
                            // {
                            //     type: 1,
                            //     name: '资料下载',
                            //     video: false,
                            //     url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/VR%E7%AB%8B%E6%96%B9%E7%B3%BB%E5%88%97.pdf',
                            // },
                        ],
                    },
                    {
                        img: require('../../assets/img/home/vrgroup.jpg'),
                        icon: require('../../assets/img/home/8group-vrgroup-icon.svg'),
                        title: '五星台',
                        subtitle: '小组协同交互显示产品',
                        btns: [
                            // {
                            //     type: 1,
                            //     name: '资料下载',
                            //     video: false,
                            //     url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/VR-Group%E7%B3%BB%E5%88%97.pdf',
                            // },
                        ],
                    },
                ],
            }
        },
        methods: {
            viewVideo(url) {
                layui.use('layer', function() {
                    const layer = layui.layer
                    layer.open({
                        type: 1,
                        title: false,
                        shade: [0.9, '#000'],
                        shadeClose: true,
                        // closeBtn: 0,
                        area: '100%',
                        scrollbar: false,
                        content: `<video autoplay controls src="${url}" style="width: 100%;outline: none">`,
                    })
                })
            },
        },
    }
</script>

<style scoped lang="scss">
    .eightGroup {
        padding: 0 16px;
        background-color: #121212;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .itemWrap {
            width: 168px;
            height: 288px;
            background-color: #292929;
            margin-bottom: 7px;

            .img {
                width: 100%;
                height: 112px;
            }

            .iconWrap {
                height: 50px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 10px;

                img {
                    //width: 100%;
                    // height: 100%;
                }
            }

            .title {
                font-family: arial !important;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                color: #ffffff;
                margin-top: 6px;
            }

            .subtitle {
                font-family: arial !important;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                color: #ffffff;
                margin-top: 4px;
            }

            .btns {
                display: flex;
                align-items: center;
                justify-content: center;

                .btn {
                    width: 68px;
                    height: 24px;
                    border: 1px solid #bbbbbb;
                    border-radius: 36px;
                    margin: 20px 6px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        font-family: arial !important;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        color: #ffffff;
                    }

                    &.type2 {
                        @extend .btn;
                        background: linear-gradient(312.85deg, #f89227 19.95%, #f8c71a 74.99%);
                        border: none;
                    }

                    &.type3 {
                        @extend .btn;
                        background: linear-gradient(312.04deg, #19b8be 21.11%, #67f1f7 77.09%);
                        border: none;
                    }

                    &.type4 {
                        @extend .btn;
                        background: linear-gradient(312.04deg, #004f67 21.11%, #33c6cf 77.09%);
                        border: none;
                    }

                    &.type5 {
                        @extend .btn;
                        background: linear-gradient(312.04deg, #004aa2 21.11%, #00a4fd 77.09%);
                        border: none;
                    }

                    /* 2022/04/28 mm added */
                    &.type6 {
                        @extend .btn;
                        background: linear-gradient(to right, rgba(245, 142, 33, 1),rgba(248, 199, 26, 1));
                        border: none;
                    }
                }
            }
        }
    }
</style>
